import React from "react"
// import { Script } from "gatsby"
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'
import {Promotion} from '../components/promotions/promotion'




const LodzPromocjaDlaNowychKlientow = () => {

  return (
    <Layout lodz>
    <Seo
      title="Lodz promocja"
      description="Lodz promocja"
      url="https://bodymedgroup.pl"
    />
    
    <Promotion/>
    </Layout>
  )
}



export default LodzPromocjaDlaNowychKlientow


