import React from 'react'
import styled, { css } from 'styled-components'
import { device } from '../../utils/breakpoints'
import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'

const StyledContainer = styled(Container) `
    grid-template-columns: auto;
    gap: 2rem;
    justify-items: center;

    @media ${device.laptop} {
        grid-template-columns: repeat(2, 1fr);
    }
`

const ContainerInformations = styled.div `
    display: grid;
    gap: 2rem;
    align-content: start;

`
const ContainerForm = styled.div `


`
const StyledSpan = styled.span `
    line-height: 120%;
    font-weight: 700;
`

const StyledHeading = styled(Heading) `
${({ h1 }) =>
    h1 &&
    css`
      text-transform: unset;
      font-size: ${({ theme }) => theme.fonts.size.m};
      line-height: 150%;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.green};
      @media ${device.tablet} {
        font-size: ${({ theme }) => theme.fonts.size.l};
      }
    `}

    ${({ h2 }) =>
    h2 &&
    css`
      text-transform: unset;
      font-size: ${({ theme }) => theme.fonts.size.m};
      line-height: 120%;
      color: ${({ theme }) => theme.colors.green};
      @media ${device.tablet} {
        font-size: ${({ theme }) => theme.fonts.size.m};
      }
    `}

    ${({ h3 }) =>
    h3 &&
    css`
      text-transform: unset;
      font-size: ${({ theme }) => theme.fonts.size.m};
      line-height: 120%;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.green};
      @media ${device.tablet} {
        font-size: ${({ theme }) => theme.fonts.size.m};
      }
    `}
`

const ContainerBoxes = styled.div `
    display: grid;
    gap: 1rem;
   
`
const StyledBox = styled.div `
    display: grid;
    gap: 1rem;
    padding: 1.5rem;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03); 
    box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03);
`

export const Promotion = () => {

    return (
        <Wrapper>
            <StyledContainer>
                <ContainerInformations>
                    <StyledSpan>Marzysz o gładkiej skórze? Od dawna zastanawiasz się nad zabiegami depilacji laserowej? Takiej oferty jeszcze nie było!</StyledSpan>
                    <StyledHeading h1 as='h1'>
                    Depilacja Laserowa medycznym laserem LightSheer® DUET™ oraz LightSheer® Desire™ dla Nowych Klientów taniej o 150 zł!
                    </StyledHeading>
                    <StyledHeading h2 as="h2">Pobierz za darmo VOUCHER o wartości 150 zł i zapłać za zabieg wybranej części ciała jedynie 49 zł.</StyledHeading>
                    <p>Wypełnij formularz dostępny na naszej stronie internetowej, w zamian otrzymasz zaproszenie na zabieg oraz VOUCHER do wykorzystania do jednego z naszych 3 gabinetów w Toruniu, w Bydgoszczy lub w Łodzi.</p>
                    <ContainerBoxes>
                        <StyledBox>
                            <StyledHeading h3 as="h3">Konsultacja Kosmetologiczna</StyledHeading>
                            <p>W trakcie konsultacji z naszym kosmetologiem wnikliwie ocenimy Twoją skórę oraz kolor i grubość włosa. Pozwoli to na odpowiednie dopasowanie parametrów, tak aby skuteczność zabiegowa była na najwyższym poziomie.</p>
                        </StyledBox>
                        <StyledBox>
                            <StyledHeading h3 as="h3">Próba laserowa LightSheer®</StyledHeading>
                            <p>Wykonamy tzw. próbę laserową, dzięki niej zyskamy pewność, że wiązka lasera została dopasowana odpowiednio a zabieg będzie w pełni efektywny i bezpieczny dla Twojej skóry.</p>
                        </StyledBox>
                        <StyledBox>
                            <StyledHeading h3 as="h3">Pierwszy, pełnowartościowy zabieg epilacji</StyledHeading>
                            <p>Możesz wybrać absolutnie każdą partię ciała, której cenę obniżymy o wartość vouchera, czyli o 150 zł.</p>
                        </StyledBox>
                    </ContainerBoxes>
                </ContainerInformations>
                <ContainerForm>
                    <getresponse-form form-id="1d454422-82bb-4357-b4c7-97be6d47267d" e="0"></getresponse-form>
                </ContainerForm>
            </StyledContainer>
        </Wrapper>
    )
}